export default defineNuxtRouteMiddleware((to) => {
  // If there's a code parameter and additional parameters
  if (to.query.code && Object.keys(to.query).length > 1) {
    // Create new query with only the code
    return navigateTo({
      path: '/auth/google',
      query: {
        code: to.query.code as string
      }
    }, { replace: true });
  }
}); 